<template>
  <div
    class="
      widgetContainer
      bg-white
      widgetContainer--scrollable
      moveFundsOutAmount
    ">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="backAction" />
        <p class="title">
          {{ $t('move_fund_navTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form class="wise-form" ref="moveFundsAmount" :model="formData">
        <el-form-item
          prop="selectedBank"
          class="is-no-asterisk"
          :label="$t('pay_section_source')">
          <el-input :value="getSelectedAccount.label" />
        </el-form-item>

        <el-form-item
          prop="selectedBank"
          class="is-no-asterisk"
          :label="$t('pay_section_destination')">
          <el-select v-model="formData.selectedBank" style="width: 100%">
            <el-option
              v-for="bank in getSelfContacts"
              :key="bank.id"
              :label="computedTitle(bank)"
              :value="bank.id" />
          </el-select>
        </el-form-item>

        <el-form-item
          class="is-no-asterisk"
          prop="amount"
          :label="$t('pay_row_amount')">
          <Amount :masked="true" v-model="formData.amount" />
        </el-form-item>

        <el-form-item
          prop="selectedBank"
          class="is-no-asterisk"
          :label="$t('description')">
          <el-input v-model="formData.description" />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="isFormValid"
        @click="sendTransfer"
        class="el-button__brand brand width-100">
        {{ $t('transfer') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Amount from '@/components/Amount';
import { mapActions, mapGetters } from 'vuex';
import { mask } from '@/utils/general';

export default {
  components: {
    Amount
  },
  data() {
    return {
      formData: {
        selectedBank: '',
        amount: '',
        description: ''
      }
    };
  },
  computed: {
    ...mapGetters('contact', ['getSelfContacts']),
    ...mapGetters('fund', ['getMoveFundDetails']),
    ...mapGetters('account', ['getSelectedAccount']),
    isFormValid() {
      return !(
        !!this.formData.selectedBank &&
        !!this.formData.amount &&
        !!this.formData.description
      );
    }
  },
  created() {
    if (this.getMoveFundDetails && this.getMoveFundDetails.contact) {
      this.formData.selectedBank = this.getMoveFundDetails.contact.id;
    }
  },
  methods: {
    ...mapActions('fund', ['sendAch']),
    backAction() {
      this.drawerBack();
    },
    sendTransfer() {
      const loader = this.showLoader();
      this.sendAch({
        accountId: this.getSelectedAccount.id,
        amount: '' + this.formData.amount,
        contactId: this.formData.selectedBank,
        description: this.formData.description
      })
        .then(() => {
          this.$emit('goTo', 'moveFundsOutSuccess');
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    computedTitle(b) {
      return b.ach.bankName + ' ' + mask(b.ach.accountNumber);
    }
  }
};
</script>

<style lang="scss">
.moveFundsOutAmount {
  position: relative;

  .el-button__brand {
    text-transform: capitalize;
  }
}
</style>
